import { InputAdornment, TextField } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export const returnCurrency = (cents) => {
  if (isNaN(cents)) {
    return "Invalid number";
  }

  // Convert cents to a dollar amount
  const dollars = cents / 100;

  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2, // force two decimal digits
    maximumFractionDigits: 2, // force two decimal digits
  }).format(dollars);

  return formatted;
};

export const formatNumberWithCommas = (value) => {
  // Ensure that we're formatting only if there's a value
  if (!value) return "";
  let parts = value.split(".");
  let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Reassemble the parts to include the decimal, if present
  return parts.length === 2 ? `${integerPart}.${parts[1]}` : integerPart;
};

export const removeCommasAndReturnCents = (value) => {
  console.log("value", value);
  if (value === "-" || value === 0 || value === "0") {
    value = 0;
    return value;
  } else {
    return Math.round(parseFloat(value.toString().replace(/,/g, "")) * 100);
  }
};

export const returnCurrencyInput = (input) => {
  console.log(`Argument to convert to currency: ${input}`);
  // Convert input to string if it's not already
  let inputString = typeof input === "string" ? input : input.toString();
  let value = inputString.replace(/,/g, "");

  const dotIndex = value.indexOf(".");
  if (dotIndex !== -1 && value.length - dotIndex - 1 > 2) {
    value = value.substring(0, dotIndex + 3);
  }

  const isNumberOrDecimal = /^-?(\d+\.?\d{0,2}|\.\d{1,2})$/.test(value);

  if (value === "" || isNumberOrDecimal) {
    const formattedValue = formatNumberWithCommas(value);
    const centsInt = Math.round(parseFloat(value).toFixed(2) * 100);
    return {
      formattedDollarValue: formattedValue,
      centsInt: centsInt,
    };
  }
  // Optional: Return some default or error state if the input is invalid
  return {
    formattedDollarValue: "",
    centsInt: 0,
  };
};

export const formatCurrency = (valueIn) => {
  console.log("valueIn", valueIn);
  if (!isNaN(valueIn)) {
    console.log("Number in cents?");
    // Convert cents to a dollar amount
    const dollars = parseFloat(valueIn / 100).toFixed(2);
    console.log("dollars", dollars);
    const formatted = formatNumberWithCommas(dollars);
    console.log("formatted", formatted);
    return formatted;
  } else if (
    valueIn !== undefined &&
    valueIn !== null &&
    valueIn !== "" &&
    isNaN(valueIn)
  ) {
    const numericAmount = parseFloat(valueIn.replace(/,/g, "")).toFixed(2);
    const formattedAmount = formatNumberWithCommas(numericAmount);
    return formattedAmount;
  }
  return valueIn;
};

//Function to find and link an account given an account number and account Data
//If no match can be found, returns null
export const findAccountById = ({ accountId, accounts }) => {
  const account = accounts.find((account) => {
    return account.id === accountId;
  });
  return account;
};

//Function to find and link a fund given a fund number and fund Data
//If no match can be found, returns null
export const findFundById = ({ fundId, funds }) => {
  const fund = funds.find((fund) => {
    return fund.id === fundId;
  });
  return fund;
};

export const findContactNameById = ({ contactId, contacts }) => {
  // console.log("contactId", contactId);
  // console.log("contacts", contacts);
  const contact = contacts.find((contact) => {
    return contact.id === contactId;
  });
  if (!contact) {
    console.error("No contact found for contactId: ", contactId);
    return "Journal Entry";
  }
  return contact.shortName;
};

export const findDonorName = ({ donorId, donors }) => {
  const donor = donors.find((donor) => {
    return donor.id.some((idItem) => idItem === donorId);
  });
  if (!donor) {
    console.error("No donor found for donorId: ", donorId);
    return "No Donor Found";
  }
  return donor.shortName;
};

export const convertToDate = (date) => {
  if (dayjs.isDayjs(date)) {
    // Already a Day.js object, just return it
    return date;
  } else if (typeof date === "string") {
    // Convert string to Day.js object
    return dayjs(new Date(date));
  } else if (date instanceof Date) {
    // Convert JavaScript Date object to Day.js object
    return dayjs(date);
  } else if (date && typeof date.seconds === "number") {
    // Convert from seconds to Day.js object
    return dayjs(date.seconds * 1000);
  } else if (date && date.$D && date.$y) {
    // Handle special object that looks like Day.js
    return dayjs(
      new Date(date.$y, date.$M, date.$D, date.$H, date.$m, date.$s, date.$ms),
    );
  } else {
    // Unsupported type
    throw new Error(`Unsupported date type: ${JSON.stringify(date)}`);
  }
};

export const generateRandomId = () => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let autoId = "";
  for (let i = 0; i < 20; i++) {
    autoId += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return autoId;
};

export const amountToCentsInt = (amount) => {
  if (isNaN(parseFloat(amount))) {
    return null;
  } else {
    return Math.round(parseFloat(amount) * 100);
  }
};

export const centsToDollarString = (cents) => {
  if (isNaN(cents)) {
    return null;
  } else {
    // Divide by 100 to convert cents to dollars and format to two decimal places
    return (cents / 100).toFixed(2);
  }
};

export const sortAccountsByType = (
  accounts,
  accountTypeOrder,
  selectedAccount = "",
  disabledAccountType = "",
) => {
  //Check if there are accounts, return an empty array if there are none
  if (!accounts) {
    return [];
  }
  return accounts
    .filter(
      (account) =>
        account.id !== selectedAccount &&
        account.accountType.toLowerCase() !==
          disabledAccountType.toLowerCase() &&
        !account.disabled,
    )
    .sort((a, b) => {
      // Get the order index for each account type
      const orderA = accountTypeOrder.indexOf(a.accountType.toLowerCase());
      const orderB = accountTypeOrder.indexOf(b.accountType.toLowerCase());

      // First, sort by the predefined account type order
      if (orderA !== orderB) {
        return orderA - orderB;
      }

      // Then, sort by account number within each type
      return a.accountNumber.toString().localeCompare(b.accountNumber);
    });
};

// // Usage
// const accountTypeOrder = ["expenses", "income", "assets", "liabilities"];
// const disabledAccountType = "Equity"; // Can be any account type or left blank
// const sortedAccounts = sortAccountsByType(accounts, accountTypeOrder, disabledAccountType);

// options = {
//   accounts?.length > 0 ? sortedAccounts : []
// };

export function removeDuplicatesByField(array, fieldName) {
  const seen = new Set();
  return array.filter((item) => {
    const value = item[fieldName];
    if (seen.has(value)) {
      return false;
    } else {
      seen.add(value);
      return true;
    }
  });
}

export const unixDateToString = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000); // Ensure multiplication by 1000 for milliseconds
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

export const firebaseTimestampToString = (firebaseTimestamp) => {
  // Convert Firebase Timestamp to JavaScript Date object
  const date = firebaseTimestamp.toDate(); // Assuming firebaseTimestamp is a Firebase Timestamp object

  // Format the date
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const generateResponsiveStyles = (breakpoints) => {
  return breakpoints
    .map((breakpoint) => {
      const mediaQuery = `@media (max-width: ${breakpoint.size}px) {`;
      const styles = Object.entries(breakpoint.styles)
        .map(([key, value]) => `${key}: ${value};`)
        .join(" ");
      return `${mediaQuery} ${styles} }`;
    })
    .join("");
};

export const shortenText = (text) => {
  //If the name is more than than 30 characters, shorten it by showing the first letter of each word if there are three or more words, otherwise only show the first word that is longer than three letters.
  if (text.length > 15) {
    const words = text.split(" ");
    if (words.length > 2) {
      return words
        .map((word) => {
          return word[0];
        })
        .join("");
    } else {
      return words.find((word) => word.length > 3);
    }
  }
  return text;
};

export const extremeShortenText = (text) => {
  if (text.length > 7) {
    const words = text.split(" ");
    if (words.length > 3) {
      return words
        .map((word) => {
          return word[0];
        })
        .join("");
    } else {
      return text.substring(0, 6) + "...";
    }
  } else {
    return text;
  }
};

// // Usage for text component
// const textStyle = css`
//   ${generateResponsiveStyles([
//     { size: 2200, styles: { "font-size": "1.4rem" } },
//     { size: 1200, styles: { "font-size": "1.2rem" } },
//     // Add more breakpoints as needed
//   ])}
// `;

// // Usage for another component that requires width adjustments
// const boxStyle = css`
//   ${generateResponsiveStyles([
//     { size: 2200, styles: { width: "50%", "font-size": "1.4rem" } },
//     { size: 1200, styles: { width: "70%", "font-size": "1.2rem" } },
//     // Add more breakpoints and styles as needed
//   ])}
// `;

export const CurrencyInput = ({
  currencyValueCents,
  setCurrencyValueCents,
  label,
  size,
  onBlur,
  helperText,
}) => {
  const [currencyDisplayValue, setCurrencyDisplayValue] = useState(() => {
    if (currencyValueCents === "") {
      console.log("currencyValueCents is empty");
      return "";
    } else {
      const initialDollarValue = centsToDollarString(currencyValueCents);
      const { formattedDollarValue } = returnCurrencyInput(initialDollarValue);
      console.log("INITIAL formattedDollarValue", formattedDollarValue);
      return formattedDollarValue || "";
    }
  });

  const handleChangeAmount = (event) => {
    let value = event.target.value;
    // Check if the value is just "-"
    if (value === "-" || value === "-." || value === "-.0") {
      setCurrencyDisplayValue(value);
      return; // Exit early to avoid further processing
    }

    let cleanValue = value.replace(/[,\\]/g, "");
    // Count "-" occurrences
    const dashCount = (cleanValue.match(/-/g) || []).length;

    // Toggle the negative sign based on the count of "-"
    if (dashCount % 2 === 1) {
      // Ensure "-" is at the beginning if the count is odd
      cleanValue = "-" + cleanValue.replace(/-/g, "");
    } else {
      // Remove all "-" if the count is even
      cleanValue = cleanValue.replace(/-/g, "");
    }

    // Validate the resulting value
    const isNumberOrDecimal = /^-?\d*\.?\d*$/; // Allow numbers with or without leading "-", and with or without decimals
    if (cleanValue === "" || isNumberOrDecimal.test(cleanValue)) {
      // Format the display value, including any decimals accurately
      console.log("cleanValue", cleanValue);
      const { centsInt, formattedDollarValue } =
        returnCurrencyInput(cleanValue);

      setCurrencyValueCents(centsInt);
      setCurrencyDisplayValue(formattedDollarValue);
    } else {
      // Optionally handle invalid input, such as by resetting it to the last valid state or providing feedback
    }
  };

  //useEffect to handle when currencyValueCents changes externally
  useEffect(() => {
    if (currencyValueCents === "") {
      setCurrencyDisplayValue("");
    }
  }, [currencyValueCents]);

  const addCentsOnBlur = (value) => {
    if (value.includes(".")) {
      let parts = value.split(".");
      if (parts[1].length === 1) {
        // If there is only one digit after the decimal
        return value + "0";
      } else if (parts[1].length === 2) {
        // If there are already two digits after the decimal
        return value;
      } else {
        // If there are more than two digits after the decimal, truncate to two digits
        return parts[0] + "." + parts[1].substring(0, 2);
      }
    } else {
      // If there is no decimal point
      return value + ".00";
    }
  };

  return (
    <TextField
      id="balance"
      name={`balance`}
      label={label ? label : ""}
      color="secondary"
      value={currencyDisplayValue}
      onChange={handleChangeAmount}
      InputProps={
        currencyValueCents !== "" && {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }
      }
      variant="outlined"
      onBlur={() => {
        if (
          currencyValueCents === "" ||
          currencyDisplayValue === "" ||
          currencyDisplayValue === "-" ||
          currencyDisplayValue === "-." ||
          currencyDisplayValue === "-.0"
        ) {
          setCurrencyDisplayValue("");
        } else {
          setCurrencyDisplayValue(addCentsOnBlur(currencyDisplayValue));
        }
        if (onBlur) {
          onBlur();
        }
      }}
      size={size ? size : "medium"}
      helperText={helperText ? helperText : ""}
    />
  );
};

export const instantLog = ({ obj, msg }) => {
  const objString = JSON.stringify(obj);
  const objJSON = JSON.parse(objString);
  console.log(msg, objJSON);
};

export const deepClone = (obj) => {
  // Check if 'structuredClone' is available in the current environment
  if (typeof structuredClone === "function") {
    return structuredClone(obj);
  } else {
    // Fallback to JSON.stringify/JSON.parse for deep cloning
    return JSON.parse(JSON.stringify(obj));
  }
};
